(function($){
  function place_video() {
    $('#rev_slider_index_top .rs-background-video-layer').css({top: $(window).scrollTop() + 'px'});
  }

  $(document).ready(function() {
    if ($('#rev_slider_index_top').size() > 0) {
      $('#rev_slider_index_top').show().revolution({sliderLayout: 'fullscreen', dottedOverlay: 'twoxtwo'});
      place_video();
    }
  });
  $(window).scroll(function () {
    if ($('#rev_slider_index_top').size() > 0) {
      place_video();
    }
  });
})(jQuery);
