(function ($) {

    var currentStep = 0;
    var completedSteps = 0;
    var timeOut = null;
    // one time after navigating to step "1" billing address we will pre fill the form
    var prefilledBillingAddress = false, prefilledDeliveryAddress = false;
    var codes_entered = 0;

    // select field placeholder
    $('select.has-placeholder').on('change', onSelectChange);
    $('[name=bankaccount_iban]').prop('required', false);
    $('[name=bankaccount_bic]').prop('required', false);
    $('[name=cc_firstname]').prop('required', false);
    $('[name=cc_lastname]').prop('required', false);

    function onSelectChange() {
        var $that = $(this);
        $that.removeClass('has-placeholder');
        $that.off('change', onSelectChange);
    }

    //referred dropdown
    $('.referred-dropdown-header').click(function () {
        $('.referred-dropdown-form').toggleClass('hidden-animated');
        $('.referred-dropdown-header .icon-right').toggleClass('active');
        $('.referred-dropdown-header p').toggleClass('hidden');

        // emtpy out referred input fields if closing the referrral form
        if ($('.referred-dropdown-form').hasClass('hidden-animated'))
        {
            $('.referred-dropdown-form input').val('');
        }

    });

    function show_advanced_form () {
      validateForm(function() {
          //$('.entry-form').addClass('hidden');
          $('.advanced-form .registration').removeClass('blur').addClass('active');
          $('.advanced-form').removeClass('hidden');
          $('#show-advanced-form').prop('disabled', true);
          var el_1 = $('[name=card_type_id]').attr('name', 'card_type_id_tmp'), 
            el_2 = $('[name=start_date]').attr('name', 'start_date_tmp');
            el_3 = $('[name=region_id]').attr('name', 'region_id_tmp');
          $('<input type="hidden" name="card_type_id"/>').val(el_1.val()).insertBefore(el_1);
          $('<input type="hidden" name="start_date"/>').val(el_2.val()).insertBefore(el_2);
          $('<input type="hidden" name="region_id"/>').val(el_3.val()).insertBefore(el_3);
          el_1.prop('disabled', true);
          el_2.prop('disabled', true);
          el_3.prop('disabled', true);

          var scrollTop = $('.advanced-form .order-step').eq(currentStep).offset().top - 100;
          $('html, body').stop().animate({
              scrollTop: scrollTop
          }, 750);
      });
    }

    // send entry form
    $('#show-advanced-form').click(function (e) {
        e.preventDefault();
        show_advanced_form();
        return false;
    });

    // go back to entry form
    $('#back-to-card-choice').click(function (e) {
        e.preventDefault();
        $('.entry-form').removeClass('hidden');
        $('.advanced-form').addClass('hidden');
        $('.advanced-form .order-step.active').removeClass('active').addClass('blur');
    });

    //advanced form
    //------------------------

    $('.go-to-signup, .go-to-login').click(function (e) {
        e.preventDefault();
        $('.registration .login, .registration .signup').toggleClass('hidden');
    });

    // order steps accordion
    $('.step-header').click(function (e) {
        e.preventDefault();
        goToStep($(this).closest('.order-step').index());
    });

    function deactivateSteps() {
        $('.order-step').removeClass('active');
    }

    function renderConditionalElements() {
        // only display checked arrow icon and edit link on completed steps

        $('.numbered-headline .headline , .edit-step, .step-header .summary').each(function () {
            var $this = $(this);
            if ($this.closest('.order-step').index() >= completedSteps) {
                $this.removeClass('checked');
            } else {
                $this.addClass('checked');
            }
        });

        // only blur non-completed steps
        $('.order-step').each(function () {
            var $this = $(this);
            if ($this.index() > completedSteps) {
                $this.addClass('blur');
            } else {
                $this.removeClass('blur');
            }
        });
    }

    function setStepsCounter() {
        currentStep = $('.order-step.active').index();

        if (currentStep > completedSteps) {
            completedSteps = currentStep;
        }
        renderConditionalElements();
    }

    function validateForm(cb, forceCB) {

        clearFieldErrors();

        var form = $('#order-form');
      if ($('[name=user_already_has_account]').val() == 1) {
        $('[name=email]').val('');
        $('[name=email_confirmation]').val('');
        $('[name=password]').val('');
        $('[name=password_confirmation]').val('');
      }

        $.ajax({
            type: 'POST',
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function (res) {
                cb();
            },
            error: function (res) {
                var data = JSON.parse(res.responseText);
                var visibleStepIsValid = true;
                for (var key in data.errors) {
                    var inputField = form.find('[name="' + key + '"]');
                    if (inputField && inputField.closest('.order-step').hasClass('active')) { //$('[name="' + key + '"]').is(':visible')) {
                        setFieldError(key, data.errors[key]);
                        visibleStepIsValid = false;
                    }
                }

                for (var key in data.responses) {
                  if (key == 'cart_html') {
                    $('#overview-table tbody tr').not('.keep').remove();
                    $('#overview-table tbody').prepend(data.responses[key]);
                  }
                  else {
                    $('#response-' + key).html(data.responses[key]);
                  }
                }

                if (data.responses.voucher_is_valid === true) {
                    $('#response-voucher_discount').closest('tr').removeClass('hidden');

                }
                else {
                    $('#response-voucher_discount').closest('tr').removeClass('hidden').addClass('hidden');
                    if ($('[name=voucher_code_used]').val() != '') {
                      codes_entered++;
                      if (codes_entered > 2) {
                        alert('Zuviele Falscheingaben.');
                        window.location.reload();
                      }
                    }
                }
                if (data.responses.total_raw == 0) {
                  $('#payment_method_select').hide();
                  $('[name="payment_method"]').prop('required', false);
                  $('[name="zfm4"]').val("1");
                  $('#pay-button').text('Bestellen');
                }
                else {
                  $('#payment_method_select').show();
                  $('[name="payment_method"]').prop('required', true);
                  $('[name="zfm4"]').val("0");
                  $('#pay-button').text('Bezahlen');
                }

                // if the active step is valid
                // we call the callback anyway
                if (visibleStepIsValid || forceCB)
                {
                    cb();
                }
            }
        });

    }

    function setFieldError(key, msgs) {
        $('[name="' + key + '"]').closest('.form-group').addClass('error').find('.error-label').html(msgs.join('<br>'));
    }

    function clearFieldErrors() {
        $('.form-group.error').removeClass('error').find('.error-label').html('');
    }

    function goToStep(step) {
        if (step >= 0) {
            $('#cc-error').removeClass('error');
            // do not validate when going back to a previous step
            if (currentStep > step) {
                deactivateSteps();
                $('.advanced-form .order-step').eq(step).addClass('active');
                setStepsCounter();

                var scrollTop = $('.advanced-form .order-step').eq(currentStep).offset().top;
                $('html, body').stop().scrollTop(scrollTop + 1);
            }
            else {
                validateForm(function () {
                    deactivateSteps();
                    $('.advanced-form .order-step').eq(step).addClass('active');
                    setStepsCounter();

                    if(!prefilledBillingAddress && step === 1){
                        prefillBillingAddress();
                        prefilledBillingAddress = true;
                    }

                    if(!prefilledDeliveryAddress && step === 2){
                        prefillDeliveryAddress();
                        prefilledDeliveryAddress = true;
                    }

                    var scrollTop = $('.advanced-form .order-step').eq(currentStep).offset().top;
                    $('html, body').stop().scrollTop(scrollTop + 1);
                });
            }

        }
        else {
            // finally, send the form for the last button
            if ($('[name=payment_method]').val() == 'credit_card') {
              $('#ccdone').val(0);
              check();
              var check_timeout = window.setInterval(function () {
                if ($('#ccdone').val() == "1") {
                  window.clearTimeout(check_timeout);
                  if ($('#pseudocardpan').val() != '') {
                    validateForm(function () {
                        $('#order-form').submit();
                    });
                  }
                }
              }, 100);
              return false;
            }
            validateForm(function () {
                $('#order-form').submit();
            });
        }

    }

    function prefillBillingAddress() {
      var fields = ['salutation', 'title', 'first_name', 'last_name', 'company_name', 'street', 'housenumber', 'address_add', 'zip_code', 'city', 'country', 'email'];
        if($('[name="user_already_has_account"]').val() == "1"){
            var data = $('.registration.order-step').data('user');
            for (var i = 0; i < fields.length; i++) {
              $('[name="billing_'+ fields[i] +'"]').val(data[fields[i]]);
            }
            $('[name="billing_email_2"]').val(data['email']);
        }else{
            $('.step-body.signup').find('input, select').each(function(i, el){
                $('[name="billing_'+ $(el).attr('name') +'"]').val($('[name="'+ $(el).attr('name') +'"]').val());
            });
            $('[name="billing_email_2"]').val($('[name="email"]').val());
        }

    }

    function prefillDeliveryAddress() {
      var fields = ['salutation', 'title', 'first_name', 'last_name', 'company_name', 'street', 'housenumber', 'address_add', 'zip_code', 'city', 'country'];
      for (var i = 0; i < fields.length; i++) {
        $('[name="deliver_to_'+ fields[i] +'"]').val($('[name="billing_'+ fields[i] +'"]').val());
      }
    }

    // prev step, next step buttons
    $('.prev-step').click(function (e) {
        e.preventDefault();
        goToStep($(this).closest('.order-step').prev().index());
    });
    $('.next-step').click(function (e) {
        e.preventDefault();
        goToStep($(this).closest('.order-step').next().index());
    });

    $('button#logout').click(function () {
      window.location.href = $(this).data('target')+'?redirect='+escape($(this).data('order-target')+'?so=1&oc='+$('[name=card_type_id]').val()+'&ov='+$('[name=start_date]').val()+'&or='+$('[name=region_id]').val());
      return false;
    });

    // login button
    $('button#login').click(function(e) {
        e.preventDefault();
        var order_target=$(this).data('order-target');
        $.ajax({
            type: 'POST',
            url: '/api/login',
            data: {
                email: $('#login_email').val(),
                password: $('#login_password').val(),
                _token: $('[name="_token"]').val()
            },
            dataType: 'json',
            success: function (res) {
                // set flag for order via authenticated user
                $('[name="user_already_has_account"]').val(1);
                $('.registration.order-step').data('user', res);
                if ($('[name=card_type_id]').size() == 1) { 
                  window.location.href = order_target+'?so=1&oc='+$('[name=card_type_id]').val()+'&ov='+$('[name=start_date]').val()+'&or='+$('[name=region_id]').val();
                }
                else {
                  window.location.href = order_target;
                }
                // next step (will revalidate all form input data)
                //goToStep($('.order-step.active').next().index());

            },
            error: function (res) {
                var data = JSON.parse(res.responseText);
                if (data.email)
                {
                    $('#login_email').closest('.form-group').addClass('error').find('.error-label').html(data.email.join('<br>'));
                }
                if (data.password)
                {
                    $('#login_password').closest('.form-group').addClass('error').find('.error-label').html(data.password.join('<br>'));
                }

            }
        });


    });

    // change card selection
    // - sticky card change alert on mobile
    // - set hidden field for present / no present
    // - change options of start_date selection
    function card_change_handler ($e) {
      var $selection = $e.find(':selected');

      var cardName = $selection.attr('data-title'),
          cardDetails = 'Preis: ' + $selection.attr('data-cost') + '. ' + $selection.attr('data-description'),
          cardImage = $selection.attr('data-image'),//$('.llo-card-wrapper img:visible').attr('src'),
          startLabel = $selection.attr('data-abo') == "1" ? "Start" : "Gültigkeit",
          cardInfoText = $('.card-info-text')/*,
          sticky = $('.sticky-card-alert')*/;

      /*sticky.find('img').attr('src', cardImage);
      sticky.find('.card-name').text(cardName);
      sticky.find('.card-details').html(cardDetails);*/

      cardInfoText.find('.card-name').text(cardName);
      cardInfoText.find('.card-cost').text($selection.attr('data-cost'));
      cardInfoText.find('.card-old-cost').text($selection.attr('data-old-cost'));
      if ($selection.attr('data-old-cost') != '0,00 €') {
        cardInfoText.find('.card-old-cost').show();
      }
      else {
        cardInfoText.find('.card-old-cost').hide();
      }
      cardInfoText.find('.card-details').html($selection.attr('data-description'));

      var isPresent = $selection[0].hasAttribute('data-gift') ? true : false;
      //var cardClassToShow = isPresent ? 'llo-card-ribbon' : 'llo-card';
      //$('.' + cardClassToShow).show().siblings().hide();
      $('input[name="is_present"]').val(isPresent ? 1 : 0).trigger('change');
      $('.llo-card-wrapper img').attr('src', cardImage);
      $('.llo-card-wrapper .product-sales-banner').text($selection.attr('data-sales-banner'));
      if ($selection.attr('data-sales-banner')) {
        $('.llo-card-wrapper .product-sales-banner').show();
      }
      else {
        $('.llo-card-wrapper .product-sales-banner').hide();
      }

      $('select[name="start_date"]').prev('label').text(startLabel);

      var cardTypeId = $e.find('option:selected').val();
      $('select[name="start_date"] option').each(function(index, option) {
          option.textContent = option.getAttribute('data-option-' + cardTypeId);
      });
      $('select[name="payment_method"]').find('option[value="paypal"], option[value="giropay"], option[value="sofortueberweisung"]').prop('disabled', $selection.attr('data-abo') == "1");
      $('.payment-abo-hint').css('display', $selection.attr('data-abo') == "1" ? 'block' : 'none');

      /*if(!blockTrigger){
          sticky.addClass('active');
          clearTimeout(timeOut);
          timeOut = setTimeout(function () {
              sticky.removeClass('active');
          }, 4000);
      }
      blockTrigger = false;*/
    }

    function region_handler ($e) {
      var $selectedOption = $e.find('option:selected')
      $('#response-region').html($selectedOption.html());
    }

    function start_date_handler ($e) {
      var $selectedOption = $e.find('option:selected')
      // set card validity in summary display (end of order process)
      $('#response-validity').html($selectedOption.html());
      // set display state of hint-immediately text
      if($selectedOption.attr('data-immediately') == "1"){
          $('[data-immediately-hint]').addClass('show');
      }else{
          $('[data-immediately-hint]').removeClass('show');
      }
    }

    //var blockTrigger = true;
    $('[name="card_type_id"]').on('change', function (e) {
      card_change_handler($(this));
    }).trigger('change');

    $('[name="start_date"]').on('change', function (e) {
      start_date_handler($(this));
    }).trigger('change');

    $('[name="region_id"]').on('change', function (e) {
      region_handler($(this));
    }).trigger('change');

    $('[name="payment_method"]').on('change', function (e) {
      $('#cc-error').removeClass('error');
      if ($(this).val() == 'sofortueberweisung' || $(this).val() == 'giropay' || $(this).val() == 'debit') {
        $('#bankaccount-info').show();
        $('[name=bankaccount_iban]').prop('required', true);
        $('[name=bankaccount_bic]').prop('required', true);
      }
      else {
        $('#bankaccount-info').hide();
        $('[name=bankaccount_iban]').prop('required', false);
        $('[name=bankaccount_bic]').prop('required', false);
      }
      if ($(this).val() == 'credit_card') {
        $('#cc-info').show();
        $('[name=cc_firstname]').prop('required', true);
        $('[name=cc_lastname]').prop('required', true);
      }
      else {
        $('#cc-info').hide();
        $('[name=cc_firstname]').prop('required', false);
        $('[name=cc_lastname]').prop('required', false);
      }
    }).trigger('change');

    // go to card when clicking on sticky alert
    /*$('.sticky-card-alert').click(function (e) {
        e.preventDefault();
        $(this).removeClass('active');
        var scrollTop = $('.llo-card-wrapper').offset().top;
        $('html, body').stop().animate({scrollTop: scrollTop + 1}, 500);
    });*/

    // billing different address
    $('[name="billing_address_choice"]').on('change', function (e) {
        if ($(this).find('option:selected').val() === 'different_address') {
            $('.different-billing-address').removeClass('hidden');
        } else {
            $('.different-billing-address').addClass('hidden');
        }
    });

    // delivery to different address
    $('[name="delivery_address_choice"]').on('change', function (e) {
        if ($(this).find('option:selected').val() === 'different_address') {
            $('.different-delivery-address').removeClass('hidden');
        } else {
            $('.different-delivery-address').addClass('hidden');
        }
    });

    // show voucher code input field
    $('#insert-voucher-code').on('click', function(e) {
        e.preventDefault();
        var voucherCodeInput = $('[name="voucher_code_used"]');
        voucherCodeInput.closest('.row').toggleClass('hidden');
        if (voucherCodeInput.closest('.row').hasClass('hidden')
            && voucherCodeInput.closest('.form-group').hasClass('error')) {
            voucherCodeInput.val('');
            voucherCodeInput.closest('.form-group').removeClass('error');
        }
    });

    $('#check-voucher-code').on('click', function(e) {
        e.preventDefault();
        validateForm(function() {
            // not so nice workaround to "prevent" validation errors on terms when checking voucher
            $('[name="terms_accepted"]').closest('.form-group').removeClass('error').find('.error-label').html('');
            $('[name="no_retreat_accepted"]').closest('.form-group').removeClass('error').find('.error-label').html('');
            $('[name="payment_method"]').closest('.form-group').removeClass('error').find('.error-label').html('');
        }, true);
    });
    $(document).ready(function () {
      var query = {}, qstr = window.location.search;
      var a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
      for (var i = 0; i < a.length; i++) {
          var b = a[i].split('=');
          query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
      }
      if (query.or !== undefined) {
        $('[name=region_id]').val(query.or);
      }
      if (query.so == 1 && query.oc !== undefined) {
        $('[name=card_type_id]').val(query.oc);
        card_change_handler($('[name=card_type_id]'));
        if (query.ov !== undefined) {
          $('[name=start_date]').val(query.ov);
          start_date_handler($('[name=start_date]'));
          window.setTimeout(function () { show_advanced_form(); }, 500);
        }
      }
    });

})(jQuery);
