(function (lloApp, $) {
    var LOG = '[lloApp]';


    function init() {
        general();
        startPage();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
    }

    var general = function () {
        $('.slider.slider-horizontal').slick({
            accessibility: true,
            dots: true,
            speed: 200,
            arrows: true,
            fade: true,
            cssEase: 'ease-out',
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>'
        });

        verticalSlider();
    };

    var verticalSlider = function () {
        var currentSlideIndex = 0,
            lastMediaQuery,
            currMediaQuery,
            $slider = $('.slider.slider-fullscreen-vertical'),
            $slides = $slider.children('div');

        if ($slider) {
            function onNextClick(e) {
                e.preventDefault();

                if (currentSlideIndex + 1 <= ($slides.length - 1)) {
                    currentSlideIndex += 1;

                    scrollToSlide(currentSlideIndex);
                }
            }

            function onNavClick(e) {
                e.preventDefault();

                var $el = $(this);
                currentSlideIndex = $el.attr('data-slide');

                scrollToSlide(currentSlideIndex);
            }

            function scrollToSlide(id) {
                var $slide = $slider.find('.slide').eq(id),
                    windowHeight = $(window).height(),
                    slideHeight = $slide.height(),
                    scrollTop = $slide.offset().top - 70;

                if (slideHeight < windowHeight) {
                    scrollTop -= 200;
                }

                if(id === 1 && $('[data-page="faq"]').length > 0){
                    scrollTop += 300;
                }

                $('html, body').animate({
                    scrollTop: scrollTop
                }, 1000);
            }

            function onScroll() {
                // Get container scroll position
                var fromTop = $(this).scrollTop();

                // Get id of current scroll item
                var cur = $slides.map(function () {
                    if ($(this).offset().top < (fromTop + 300))
                        return this;
                });

                // Get the id of the current element
                var id = cur && cur.length ? cur.last().index() : 0;

                currentSlideIndex = id;

                // Set/remove active class
                var $navItem = $('.slider-nav a[data-slide="' + id + '"]').parent();
                $navItem.siblings().removeClass('active');
                $navItem.addClass('active');

                var $slideDownBtn = $slider.find('.slider-scroll-down');
                // slide down button
                if (currentSlideIndex < ($slides.length - 1)) {
                    $slideDownBtn.show();
                } else {
                    $slideDownBtn.hide();
                }
            }

            function onResize() {
                currMediaQuery = lloApp.getMediaQuery();

                if (lastMediaQuery !== currMediaQuery) {
                    lastMediaQuery = currMediaQuery;

                    // fullscreen mode only on desktop
                    if (currMediaQuery === 'desktop') {
                        // highlight nav items on scroll
                        $(window).off('scroll', onScroll).on('scroll', onScroll);
                        // slider nav click listener
                        $('.slider-nav a').off('click', onNavClick).on('click', onNavClick);
                        // scroll to next
                        $slider.find('.slider-scroll-down').off('click', onNextClick).on('click', onNextClick);
                    } else {
                        $(window).off('scroll', onScroll);
                        $('.slider-nav a').off('click', onNavClick);
                        $slider.find('.slider-scroll-down').off('click', onNextClick);
                    }
                }
            }

            // add fullscreen wrapper class to wrapper
            $slider.closest('.wrapper').addClass('fullscreen-slider-wrapper');

            // add slide class to children
            $slides.addClass('slide');

            // add additional nav-item
            $slider.append($('<a></a>', {class: 'slider-scroll-down icon-chevron-down', attr: {href: '#'}}));

            $(window).resize(function () {
                onResize();
            });

            onResize();

        }
    };

    var startPage = function () {
        // console.log(LOG, 'startPage');

        // init teaser isotope
        var $grid = $('.grid').isotope({
                itemSelector: '.grid-item',
                stamp: '.events',
                // no transitions
                transitionDuration: 0,
                masonry: {
                    columnWidth: 300,
                    gutter: 20
                }
            }),
            $stampElem = $grid.find('.events'),
            curr,
            old;

        updateGrid();

        // remove stamp behaviour for mobile
        $(window).on('resize', function () {
            updateGrid();
        });

        function updateGrid() {
            old = curr;
            curr = ($(window).width() < 768) ? 'phone' : (($(window).width() < 1200) ? 'tablet' : 'desktop');

            if (old != curr) {
                if (curr == 'phone') {
                    $grid.isotope('unstamp', $stampElem);
                    $grid.isotope({sortBy: 'order'});

                } else {
                    $grid.isotope('unstamp', $stampElem);
                    // move fixed element to its position...
                    $stampElem.css({top: 0, right: 0, left: 'auto'});
                    // ... and "stamp" it there
                    $grid.isotope('stamp', $stampElem);
                    // ... then sort all other items
                    $grid.isotope({sortBy: 'order'});
                }
            }
        }


        // init unveil lazy loading
        $('img[data-src]').unveil(100, function() {
            $(this).load(function() {
                $(this).closest('li.hidden').removeClass('hidden');
            });
        });
    };


    // public functions
    lloApp.init = init;
    //lloApp.getMediaQuery = getMediaQuery;
    //lloApp.verticalSlider = verticalSlider;

})(window.lloApp = window.lloApp || {}, jQuery);


$(document).ready(function () {
    window.lloApp.init();
});
