(function (lloApp, $) {
    'use strict';

    function CustomEvent(event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;

    var breakpoint = {};
    breakpoint.refreshValue = function () {

        this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');

        $('body').toggleClass('tablet', this.value === 'tablet').toggleClass('phone', this.value === 'phone').toggleClass('desktop', this.value === 'desktop');
        if (this.valueBefore && this.value !== this.valueBefore) {
            document.dispatchEvent(new CustomEvent('onBreakpoint', {detail: this.value}));
        }
        this.valueBefore = this.value;
    };

    function getMediaQuery() {
        return window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    }

    // console.log(getMediaQuery());

    // global resize handler - listen for "onBreakpoint" instead of resize
    $(window).resize(function () {
        breakpoint.refreshValue();
        // console.log('resize');
    }).resize();

    // public functions
    lloApp.getMediaQuery = getMediaQuery;


})(window.lloApp = window.lloApp || {}, jQuery);
