(function (lloApp, $) {

    var $eventSlickElement = $('#event-calendar-slider');

    var map;
    var coords = [48.160002, 11.596330];

    function init() {
        if ($('body').attr('data-page') === 'partner-detail') {

            setMap();

            $('.partner-share-button').click(function () {
                $(this).parent().toggleClass('active');
            });

            $('.share-widget .share-widget-share').click(function () { $('.share-widget').addClass('active'); });
            $('*').not('.share-widget .share-widget-share').click(function () { $('.share-widget').removeClass('active'); $(this).blur(); });

            // slick event calendar
            if ($eventSlickElement.hasClass('slick-initialized')) {
                $eventSlickElement.slick('unslick');
            }

            $eventSlickElement.slick({
                accessibility: true,
                dots: true,
                speed: 200,
                arrows: true,
                fade: true,
                touchMove: true,
                infinite: true,
                cssEase: 'ease-out',
                prevArrow: '<button type="button" class="slick-prev"></button>',
                nextArrow: '<button type="button" class="slick-next"></button>'
            });
            $eventSlickElement.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                updatePagination(nextSlide);
            });
            function updatePagination($index){
                // console.log($index)
                var $pagItems = $('.slick-dots').find('li');
                // only display 3 pagination items at a time
                // hide nav arrows at start/end of slides
                $('.slick-prev').show();
                $('.slick-next').show();
                $('.partners-tile-pagination .icon').show();
                if ($index === 0) {
                    $pagItems.addClass('hidden').slice($index, $index + 3).removeClass('hidden');
                    $('.slick-prev').hide();
                } else if ($index === $pagItems.length - 1) {
                    $pagItems.addClass('hidden').slice($index - 2, $index + 1).removeClass('hidden');
                    $('.slick-next').hide();
                } else {
                    $pagItems.addClass('hidden').slice($index - 1, $index + 2).removeClass('hidden');

                }
            }

            // open event details on click
            $eventSlickElement.find('li').on('click', function(e){
                e.preventDefault();
                $(this).toggleClass('open');
            });

            // open event that was linked to in url
            var eventId = window.location.hash.substr(1),
                $eventItem = $('#' + eventId);
            if($eventItem.length > 0){
                $eventItem.addClass('open');

                // slide to position that event is at
                var slideIndex = $eventItem.closest('div').index();
                $eventSlickElement.slick('slickGoTo', slideIndex, true);
                updatePagination(slideIndex);
            }else{
                $eventSlickElement.slick('slickGoTo', 1, true);
                $eventSlickElement.slick('slickGoTo', 0, true);
            }




        }
    }

    function getLatLng() {
      return [parseFloat($('#partner-details-map').data('lat')), parseFloat($('#partner-details-map').data('lng'))];

        var $map = $('#partner-map'),
            lat = parseFloat($map.attr('data-lat')),
            lng = parseFloat($map.attr('data-lng'));

        if(!lat || !lng){
            return coords;
        }
        return [lat, lng];

    }

    function setMap() {

        if($('#partner-map').length === 0){
            return;
        }

        // scroll over map, click to use
        $('#map-overlay').click(function(e){
            $(this).hide();
        });



        // initialize map
        map = L.map('partner-map', {
        });

        // load map
        map.setView(getLatLng(), $('#partner-details-map').data('zoom'));

        // Leaflet providers:
        // https://github.com/leaflet-extras/leaflet-providers

        // set background
        // L.tileLayer.provider('OpenStreetMap.BlackAndWhite').addTo(map);
        // L.tileLayer.provider('OpenStreetMap.Mapnik').addTo(map);
        // http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png

         //L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
         /*L.tileLayer('https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: 'Tiles courtesy of <a href="http://openstreetmap.se/" target="_blank">OpenStreetMap Sweden</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);*/
        /*L.tileLayer('https://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);*/
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 19
        }).addTo(map);
        // add marker
        var markerIcon = L.icon({
            iconUrl: '/img/map-marker.png',
            iconSize: [34, 55],
            iconAnchor: [17, 55]
        });

        $('#partner-details-map .partner-data').each(function () {
          if (!$(this).data('lat') || !$(this).data('lng')) {
            return;
          }
          var marker = L.marker([parseFloat($(this).data('lat')), parseFloat($(this).data('lng'))], {icon: markerIcon});
          marker.bindPopup($(this).data('contact-details')).openPopup();
          marker.addTo(map);
        });
    }

    // public functions
    lloApp.partnerDetailInit = init;

})(window.lloApp = window.lloApp || {}, jQuery);

$(document).ready(function () {
    window.lloApp.partnerDetailInit();
});
