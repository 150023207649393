(function ($) {

    // find fields that are dependant
    var $fieldsWithDependency = $('[data-visible-if]'),
        fieldSelectors = [];
    $fieldsWithDependency.each(function () {
        var $field = $(this),
            attrValue = $field.attr('data-visible-if'),
            pipePos = attrValue.indexOf('|'),
            selector = '[name="' + attrValue.substr(0, pipePos) + '"]';

        // console.log(selector);
        
        
        if (fieldSelectors.indexOf(selector) === -1)
            fieldSelectors.push(selector);


    });
    // console.log(fieldSelectors);
    // add dependant visibilities to selectors
    $(fieldSelectors.join(',')).change(function () {
        var $el = $(this),
            name = $el.attr('name'),
            value = $el.val();

        var $advancedSelect = $('[data-visible-if^="' + name + '"]');

        if ($advancedSelect.length > 0) {
            $advancedSelect.addClass('hidden');

            // show field that actually corresponds to selected value
            $('[data-visible-if^="' + name + '|"]').each(function () {
                var attrValue = $(this).attr('data-visible-if'),
                    pipePos = attrValue.indexOf('|');
                attrValue = attrValue.substr(pipePos);
                if (attrValue.indexOf(value) !== -1) {
                    $(this).removeClass('hidden');
                }
            });
        }
    }).trigger('change');

})(jQuery);