(function (lloApp, $) {

    function init() {
        setEventListeners();
    }

    function setFieldError(key, msgs) {
      if (key == 'captcha') {
        $('.form-group.captcha').addClass('error').find('.error-label').html(msgs);
      }
      else {
        $('[name="' + key + '"]').closest('.form-group').addClass('error').find('.error-label').html(msgs);
      }
    }

    function clearFieldErrors() {
        $('.form-group.error').removeClass('error').find('.error-label').html('');
    }

    function setEventListeners() {
        // send form
        /*$('.send-contact-us-form').click(function (e) {
	  e.preventDefault();
	  grecaptcha.execute();
        });*/

        // toggle contact and report problem form
        $('[name="contact_us_subject"]').on('change', function () {

            var $this = $(this);
            //var $val = $this.find('option:selected').val();
            $this.closest('form').toggleClass('report-problem').toggleClass('contact-form');
        });
    }

  function submit() {
            clearFieldErrors();

            var form = $('#contact-form');

		$('.send-contact-us-form').prop('disabled', true);

            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                dataType: 'json',
                success: function (res) {
                    // console.log(res);
                    form.closest('div.row').removeClass('row').html('<p>' + res + '</p>');
                    set_footer_space();
                },
                error: function (res) {
                    var data = JSON.parse(res.responseText);
                    for (var key in data.errors) {
                        setFieldError(key, data[key]);
                    }
		$('.send-contact-us-form').prop('disabled', false);
                }
            });
  }

    // public functions
    lloApp.faqInit = init;
    lloApp.faqSubmit = submit;

})(window.lloApp = window.lloApp || {}, jQuery);

$(document).ready(function () {
    window.lloApp.faqInit();
});
