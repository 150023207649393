(function (lloApp, $) {
  var hideKeyboard = function() {
      document.activeElement.blur();
      $("input").blur();
  };

    var $partnerSlickElement = $('.partners-tiles-wrapper'),
        slickInitialized = false,
        slickConfig = {
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            accessibility: true,
            adaptiveHeight: true,
            cssEase: 'ease',
            speed: 200,
            prevArrow: $('.partners-tile-pagination .pagination-left'),
            nextArrow: $('.partners-tile-pagination .pagination-right'),
            responsive: [
                {
                    breakpoint: 1200,
                    settings: 'unslick'
                }
            ]
        },
        oldMediaQuery,
        currMediaQuery = lloApp.getMediaQuery(),
        slidesCount = 0,
        PartnersPerSlide = {
            phone: 1,
            tablet: 2
        },
        detailViewActive = false,
        $pagItems,
        $dropdownFields,
        $selectHeadings;

    function init() {
        if ($('body').attr('data-page') === 'partners') {

            setFiltersFromURL();

            setEventListeners();

            //slickItUp();
            //7checkFilterDropdown();

            if ($('.partner-item').size() > 0) {
              $('.no-results').hide();
            }
            else {
               $('.no-results').show();
            }
            $('.partner-item').click(function () {
              var href = window.location.href;
              if (href.indexOf('#') >= 0)
                href=href.substr(0, href.indexOf('#'))
                window.location.href=$(this).attr('href')+'?r=' + escape(href + '#' + $(this).attr('id'));
                return false;
            });

            /*$(document).on('onBreakpoint', function(e){
                oldMediaQuery = currMediaQuery;
                currMediaQuery = e.originalEvent.detail;
                slickItUp();
                //checkFilterDropdown();
            });*/
        }
    }

    function setEventListeners(){
        // when slides change
        /*$partnerSlickElement.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $pagItems.removeClass('active');
            $pagItems.eq(nextSlide).addClass('active');

            updatePagination($pagItems.eq(nextSlide).index());
        });

        // Buttons
        //============================
        //============================

        // darker gradient on button hover
        $(".partners-tiles-wrapper").on({
            mouseenter: function () {
                $(this).parent().find('.gradient').addClass('darken');
            },
            mouseleave: function () {
                $(this).parent().find('.gradient').removeClass('darken');
            }
        }, '.go-to-partner-details');*/

        // Filter
        //============================
        //============================
        var $filterToggle = $('.partners-filter-wrapper #partners-filter-button, .partners-filter-wrapper .filter-close-button');
        var $filterWrapper = $('.partners-filter-wrapper');
        $filterToggle.click(function (e) {
            e.preventDefault();
            if ($filterWrapper.hasClass('active')) {
              $filterToggle.removeClass('active');
              $('.filter-expanded').slideUp(function () { $filterWrapper.removeClass('active'); /*$('.partners-filter-placeholder').animate({height: $('.partners-filter-wrapper').height() + 'px'});*/ });//removeClass('active');
            }
            else {
              $filterWrapper.addClass('active');
              $filterToggle.addClass('active');
              $('.filter-expanded').slideDown(function () { /*$('.partners-filter-placeholder').animate({height: $('.partners-filter-wrapper').height() + 'px'});*/ });//addClass('active');
            }
        });

        // Mobile filter dropdowns
        /*$selectHeadings = $('.filter-expanded .select-heading');
        $dropdownFields = $('.category-select .form-group, .details-select .form-group');
        $selectHeadings.click(function (e) {
          return;
            e.preventDefault();

            var $this = $(this);

            $dropdownFields.hide();
            if (!$this.hasClass('open')) {
                $(this).siblings('.form-group').show();
                $selectHeadings.removeClass('open');
                $this.addClass('open');
            } else {
                $selectHeadings.removeClass('open');
            }
        });*/

        // make filter category selection deselectable
        $('.category-select .form-group label').on('click', function (e) {
            var $input = $(this).siblings('input');
            if ($input.is(':checked')){
                e.preventDefault();
                $input.prop('checked', false);
            }
        })
    }

    /**
     * Filters
     */
     function setFiltersFromURL() {
       var a = window.location.search.substr(1).split('&');
       if (a) {
         var b = {};
         for (var i = 0; i < a.length; ++i) {
             var p=a[i].split('=', 2);
             if (p.length != 2) continue;
             if (decodeURIComponent(p[0]).search(/\[\]$/) != -1) {
               var index = decodeURIComponent(p[0]).substr(0, decodeURIComponent(p[0]).length - 2);
               if (Object.prototype.toString.call(b[index]) !== '[object Array]') {
                 b[index] = [];
               }
               b[index].push(decodeURIComponent(p[1].replace(/\+/g, " ")));
             }
             else {
               b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
             }
         }
       }

       if (Object.prototype.toString.call(b.categories) === '[object Array]') {
         for (var i = 0; i < b.categories.length; i++) {
           $('[name="categories[]"][value="' + b.categories[i] + '"]').prop('checked', true);
         }
       }
       if (Object.prototype.toString.call(b.days) === '[object Array]') {
         for (var i = 0; i < b.days.length; i++) {
           $('[name="days[]"][value="' + b.days[i] + '"]').prop('checked', true);
         }
       }
       if (Object.prototype.toString.call(b.hours) === '[object Array]') {
         for (var i = 0; i < b.hours.length; i++) {
           $('[name="hours[]"][value="' + b.hours[i] + '"]').prop('checked', true);
         }
       }
       if (Object.prototype.toString.call(b.direction) === '[object Array]') {
         for (var i = 0; i < b.direction.length; i++) {
           $('[name="direction[]"][value="' + b.direction[i] + '"]').prop('checked', true);
         }
       }
       if( typeof b.textquery === 'string' ) {
         $('[name="textquery"]').val(b.textquery);
       }
     }


    function getPartnersForFilter(){
      hideKeyboard();
        $('#partner-filter [name=textquery]').val($('#partner-filter [name=textquery]').val().trim());
        var data = $('#partner-filter').serialize();
        var ser_data = $('#partner-filter').serializeArray(), is_filters = false;
        for (var key in ser_data) {
          if (ser_data[key].name == 'categories[]' || ser_data[key].name == 'days[]' || ser_data[key].name == 'hours[]' || ser_data[key].name == 'direction[]' || (ser_data[key].name == 'textquery' && ser_data[key].value.search(/[^\s\+]/) != -1))
            is_filters = true;
        }
        $.post('/api/partners',data+'&region='+filter_region+'&url='+escape(data), function(result){
            var content = '';
            if(result.count > 0){
                content = result.html;
                $('.no-results').hide();
            }else{
                $('.no-results').show();
            }

            $('.partners-tiles-wrapper').replaceWith('<div class="partners-tiles-wrapper">' + content + '</div>');
            $('.pagination').replaceWith(result.pagination);
            //$partnerSlickElement = $('.partners-tiles-wrapper');
            //slickInitialized = false;
            //slickItUp(true);
            //updatePagination(0);

            var $filterToggle = $('.partners-filter-wrapper #partners-filter-button, .partners-filter-wrapper .filter-close-button');
            var $filterWrapper = $('.partners-filter-wrapper');
            $filterToggle.removeClass('active');
            $('.filter-expanded').slideUp(function () { $filterWrapper.removeClass('active'); set_footer_space(); /*$('.partners-filter-placeholder').animate({height: $('.partners-filter-wrapper').height() + 'px'});*/ });//removeClass('active');

            //console.log(data.categories, data.hours, data.days, data.direction, data.textquery);

            if (is_filters)
              $('#partners-filter-button').addClass('filters-active');
            else
              $('#partners-filter-button').removeClass('filters-active');

            history.pushState('data', '', location.origin + '/angebote/'+filter_region + (is_filters ? '?' + data : ''));
            set_footer_space();
            $('.partner-item').click(function () {
              var href = window.location.href;
              if (href.indexOf('#') >= 0)
                href=href.substr(0, href.indexOf('#'))
                window.location.href=$(this).attr('href')+'?r=' + escape(href + '#' + $(this).attr('id'));
                return false;
            });
            /*$('.partner-item').click(function () {
          //$('a.partner-item').on('click', function () {
              var href = window.location.href;
              if (href.indexOf('#') >= 0)
                href=href.substr(0, href.indexOf('#'))
                window.location.href=$(this).attr('href')+'?r=' + escape(href + '#' + $(this).attr('id'));
                return false;
            });*/
            //$('.partners-filter-wrapper #partners-filter-button, .filter-expanded .icon-close').removeClass('active');
            //$('.filter-expanded').removeClass('active');


        },'json');
        return false;
    }
    function resetFilter(){
      hideKeyboard();
        $('.category-select .form-group label').siblings('input').each(function () {
            $(this).prop('checked', false);
        });
        $('[name="hours[]"]').prop('checked', false);
        $('[name=partner_filter_hours_all]').prop('checked', false);
        $('[name="days[]"]').prop('checked', false);
        $('[name=partner_filter_days_all]').prop('checked', false);
        $('[name="direction[]"]').prop('checked', false);
        $('[name=partner_filter_direction_all]').prop('checked', false);
        $('[name=textquery]').val('');

        var data = $('#partner-filter').serialize();
        $.post('/api/partners',data+'&region='+filter_region, function(result){
            var content = '';
            if(result.count > 0){
                content = result.html;
                $('.no-results').hide();
            }else{
                $('.no-results').show();
            }

            $('.partners-tiles-wrapper').replaceWith('<div class="partners-tiles-wrapper">' + content + '</div>');
            $('.pagination').replaceWith(result.pagination);
            //$partnerSlickElement = $('.partners-tiles-wrapper');
            //slickInitialized = false;
            //slickItUp(true);
            //updatePagination(0);

            /*var $filterToggle = $('.partners-filter-wrapper #partners-filter-button, .partners-filter-wrapper .filter-close-button');
            var $filterWrapper = $('.partners-filter-wrapper');
            $filterToggle.removeClass('active');
            $('.filter-expanded').slideUp(function () { $filterWrapper.removeClass('active'); $('.partners-filter-placeholder').animate({height: $('.partners-filter-wrapper').height() + 'px'}); });//removeClass('active');*/

            $('#partners-filter-button').removeClass('filters-active');

            history.pushState('data', '', location.origin + '/angebote/'+filter_region + (data ? '?' + data : ''));
            set_footer_space();

            $('.partner-item').click(function () {
              var href = window.location.href;
              if (href.indexOf('#') >= 0)
                href=href.substr(0, href.indexOf('#'))
                window.location.href=$(this).attr('href')+'?r=' + escape(href + '#' + $(this).attr('id'));
                return false;
            });
        },'json');
        return false;
    }

    $('[data-action="reset"]').on('click', resetFilter);
    $('[data-action="filter"]').on('click', getPartnersForFilter);

    $('[name=partner_filter_hours_all]').on('click', function(){
        $('[name="hours[]"]').prop('checked', false);
    });
    $('[name="hours[]"]').on('click', function(){
        $('[name=partner_filter_hours_all]').prop('checked', false);
    });
    $('[name=partner_filter_days_all]').on('click', function(){
        $('[name="days[]"]').prop('checked', false);
    });
    $('[name="days[]"]').on('click', function(){
        $('[name=partner_filter_days_all]').prop('checked', false);
    });
    $('[name=partner_filter_direction_all]').on('click', function(){
        $('[name="direction[]"]').prop('checked', false);
    });
    $('[name="direction[]"]').on('click', function(){
        $('[name=partner_filter_direction_all]').prop('checked', false);
    });
    /**
     * End Filters
     */


    // Slider
    //============================
    //============================

    /*function setPagination() {

        // set number of items
        var $template = $('.pagination-item').first();

        // empty existing items
        var $list = $('.pagination-item-wrapper ul');
        $list.empty();

        // fill list with new items
        for (var i = 1; i <= slidesCount; i++) {
            $list.append($template.clone());
            $list.find('.pagination-item').last().text(i);
        }

        $pagItems = $('.pagination-item, .pagination-item:hidden');

        $pagItems.removeClass('active');
        $('.pagination-item').first().addClass('active');

        var $activeItem = $('.pagination-item.active');
        var $index = $activeItem.index();

        $partnerSlickElement.slick('slickGoTo', $index);

        $pagItems.click(function (e) {
            e.preventDefault();
            var $this = $(this);

            // set active item
            $pagItems.removeClass('active');
            $this.addClass('active');

            $partnerSlickElement.slick('slickGoTo', $this.index());
        });
    }

    function updatePagination($index){
        // only display 3 pagination items at a time
        // hide nav arrows at start/end of slides
        $('.partners-tile-pagination .icon').show();
        if ($index === 0) {
            $pagItems.addClass('hidden').slice($index, $index + 3).removeClass('hidden');
            $('.partners-tile-pagination .pagination-left').hide();
        } else if ($index === $pagItems.length - 1) {
            $pagItems.addClass('hidden').slice($index - 2, $index + 1).removeClass('hidden');
            $('.partners-tile-pagination .pagination-right').hide();
        } else {
            $pagItems.addClass('hidden').slice($index - 1, $index + 2).removeClass('hidden');
        }
    }


    // put tiles in wrappers to be displayed correctly in the carousel depending on window size
    function setSlidesForDevice(tilesPerSlide) {
        slidesCount = 0;
        var $tiles = $('.partners-tile');
        var $tileWrapper = $('.partners-tiles-wrapper');


        // empty tiles wrapper, create new slide wrappers and fill 'em up with tiles

        $tileWrapper.empty();

        var wrapper;
        $tiles.each(function (index) {
            if (index % tilesPerSlide === 0) {
                if (wrapper) {
                    $tileWrapper.append(wrapper);
                }
                wrapper = $('<div class="slide-wrapper"></div>');
                slidesCount++;
            }
            wrapper.append(this);
        });
        if (wrapper) {
            $tileWrapper.append(wrapper);
        }
    }

    function slickItUp(forceSlick) {
        forceSlick = forceSlick || false;

        // only reinitialize slick if necessary
        if (!detailViewActive && (currMediaQuery !== oldMediaQuery || !slickInitialized || forceSlick)) {

            // console.log('SLICKING');

            if (slickInitialized) {
                $partnerSlickElement.slick('unslick');
            }

            if (currMediaQuery !== 'desktop') {
                setSlidesForDevice(PartnersPerSlide[currMediaQuery]);
            }

            $partnerSlickElement.slick(slickConfig);
            slickInitialized = true;

            if (currMediaQuery !== 'desktop') {
                setPagination();
            }
        }
    }

    function checkFilterDropdown() {
        if (currMediaQuery !== 'phone') {
            $dropdownFields.show();
        } else {
            $dropdownFields.hide();
            $selectHeadings.filter('.open').siblings('.form-group').show();
        }
    }*/

    // public functions
    lloApp.partnerInit = init;
    lloApp.getPartnersForFilter = getPartnersForFilter;6

})(window.lloApp = window.lloApp || {}, jQuery);

$(document).ready(function () {
    window.lloApp.partnerInit();
});
