(function($){

    // Close main nav when clicking outside of it
    $('.main-nav').click(function(event){
        var offsetNav = $(this).offset().left,
        xClick = event.pageX;

        if (xClick < offsetNav) {
            $(this).removeClass('on-screen');
            $('body').removeClass('nav-open');
            $('.toggle-nav').removeClass('active');
            $('.navbar-fixed-top').removeClass('hidden-on-transparent');
        }
    });

    // Toggle main nav on button click
    $('.toggle-nav').click(function(){
      if ($(this).hasClass('active')) {
        $('.main-nav').removeClass('on-screen');
        $(this).removeClass('active');
        $('body').removeClass('nav-open');
        $('.navbar-fixed-top').removeClass('hidden-on-transparent');
      }
      else {
        $('.main-nav').addClass('on-screen');
        $(this).addClass('active');
        $('body').addClass('nav-open');
        $('.navbar-fixed-top').addClass('hidden-on-transparent');
      }
    });

    $('.close-nav').click(function(){
        $('.main-nav').removeClass('on-screen');
        $('.toggle-nav').removeClass('active');
        $('body').removeClass('nav-open');
        $('.navbar-fixed-top').removeClass('hidden-on-transparent');
    });

})(jQuery);
